import React from 'react';

const Footer = () => {
  return (
    <footer>
      <span>Created By <a href="#">Nitish Madabusi</a></span>
    </footer>
  );
};

export default Footer;
